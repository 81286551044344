import React, { Component } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);


class BarGraph extends Component {

  // Function to create series for the chart
  createSeries(chartRef, xLabelKey,field, name) {
    var series = chartRef.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = field;
    series.dataFields.categoryX = xLabelKey;
    series.name = name;
    series.columns.template.tooltipText = "{name}: [bold]{valueY}[/]";
    series.columns.template.width = am4core.percent(50);
  }

  componentDidMount() { 
    let chart = am4core.create(`chartdiv ${this.props.id}`, am4charts.XYChart);
    const { yLabelKey, xlegend, xLabelKey, ytitle, graphData } = this.props;

    chart.data = graphData;
    console.log(graphData)

    chart.colors.list = [
      am4core.color('#949D6A'),
     
    ];
    // Create axes
    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = xLabelKey;
    // categoryAxis.title.text = xLabelKey;
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 32;
    categoryAxis.renderer.cellStartLocation = 0.1;
    categoryAxis.renderer.cellEndLocation = 0.9;
    categoryAxis.renderer.labels.template.rotation = -55;
    categoryAxis.renderer.labels.template.horizontalCenter = "right";
    categoryAxis.renderer.labels.template.location = 0.5;
    categoryAxis.renderer.labels.template.fontSize = '1.1rem';

    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.title.text = ytitle;

    // Create series

    this.createSeries(chart, xLabelKey, yLabelKey, xlegend);


    chart.legend = new am4charts.Legend();
    this.chart = chart;
    this.categoryAxis = categoryAxis;
  }

  // componentDidUpdate(prevProps) {
  //   if (this.chart && this.props.xLabelKey !== prevProps.xLabelKey) {
  //     // Remove all the current series from chart
  //     let seriesCount = this.chart.series['_values'].length;
  //     while (seriesCount > 0) {
  //       this.chart.series.removeIndex(0);
  //       seriesCount--;
  //     }

  //     this.chart.colors.list = [
  //       am4core.color('#949D6A'),
     
  //     ];
  //     this.categoryAxis.dataFields.category = this.props.xLabelKey;

  //     this.createSeries(this.chart, this.props.xLabelKey, this.props.yLabelKey, this.props.xlegend);
    
  //   }

  //   if (this.chart && this.props.graphData !== prevProps.graphData) {
  //     this.chart.data = this.props.graphData;
  //   }
  // }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }
  render() {

    return (
      <div id={`chartdiv ${this.props.id}`} style={{ width: "100%", height: "400px" }}></div>
    )
  }
}

export default BarGraph;
