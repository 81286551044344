import React from 'react';
import Autosuggest from 'react-autosuggest';
import search from '../assets/svg/search.svg';

export const SeachBoxWithSuggestion = (props) => {
  const [query, setQuery] = React.useState("")
  const [suggestions, setSuggestions] = React.useState([])

  return (
    <div className='autosuggest'>
      <Autosuggest
        inputProps={{
          placeholder: 'Search',
          // autoComplete: '',
          name: 'query',
          id: 'query',
          value: query,
          onChange: (_event, { newValue }) => {
            setQuery(newValue)
          }
        }}
        suggestions={suggestions}
        // onSuggestionsFetchRequested={async ({ value }) => {
        // console.log(value,'suggestion')
        //   if (!value) {
        //     setSuggestions([]);
        //     return;
        //   }
        //   try {
        //     const url = `${api.queryName}?name=${value}&&circles=${encodeURIComponent(JSON.stringify(props.circle))}&region=${props.region}`;

        //     fetch(url, getAuthGetConfig(props.user.token))
        //       .then(response => {
        //         if (!response || !response.ok) throw Error(response.status);
        //         return response.json()
        //       })
        //       .then(data => {
        //         setSuggestions(data.data.map(row => ({ name: row.name })))

        //       })
        //       .catch(err => console.log('Could not get data', err));

        //   }
        //   catch (e) {
        //     setSuggestions([]);
        //   }
        // }}
        onSuggestionsClearRequested={() => {
          setSuggestions([]);
        }}
        onSuggestionSelected={(_event, { suggestion, method }) => {
          if (method === "enter") {
            props.onClickSearchBox(suggestion.name)
          }
        }}
        getSuggestionValue={suggestion => suggestion.name}
        renderSuggestion={suggestion => <div>{suggestion.name}</div>}


      />
      {props.isButton && <button className='autosuggest__btn' type='button' onClick={() => props.onClickSearchBox(query)}>
        <img src={search} alt='search' className='autosuggest__search' />
      </button>}

    </div>
  )
}
