import message from './assets/svg/chat.svg';
import dollar from './assets/svg/dollar.svg';
import document from './assets/svg/document.svg';


export const inventoryDetails = [
  { name: 'Quotes', icon: message },
  { name: 'Invoices', icon: dollar },
  { name: 'Credit Memos', icon: document }
]

export const analyzeDetails=[
  {name:'Backups',icon:message},
  {name:'Service Level Agreement',icon:message},
  {name:'Security',icon:message},
]

export const helpDetails=[
  {name:"FAQ's" ,icon:message},
  {name:"Support Requests" ,icon:message},
  {name:"Event & Training" ,icon:message},
  {name:"Contact US" ,icon:message}
]


export const settingFile=[
  {name:'User and Permissions', note:'Add or remove user ,set permissions'},
  {name:'Notifications', note:'Create budgets, turn on or off notifications'},
  {name:'Company Profile', note:'Edit your company details, update logo'},
  {name:'Credit Card', note:'Update your credit card details her quicker puchasing'},
  {name:'Approval Workflow', note:'Create and edit workflows to streamline your approvals'},
  {name:'Data Integrations', note:'Manage your cloud accounts and integrations'},
  
]