import React from 'react';
import brandLogo from '../assets/managed-cloud.png';
import { SeachBoxWithSuggestion } from './SeachBoxWithSuggestion';
import { useAuth0 } from "@auth0/auth0-react";
import LoginButton from './LoginButton';
import HeaderDropdownMenu from './DropdownMenu';
import bell from '../assets/svg/bell.svg';



const Header = () => {
  const { isAuthenticated } = useAuth0();

  return (
    <div className='header'>
      <img src={brandLogo} className='header__brand-logo' alt='brand' />

      {isAuthenticated ?
        <>
          <SeachBoxWithSuggestion />
          <div className='header__notify-dropdown'>
            <img src={bell} alt='bell' className='header__notify' />
            <HeaderDropdownMenu />
          </div>

        </>

        : <LoginButton />}
    </div>
  )

}

export default Header
