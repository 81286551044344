import React from 'react';
import { Footer } from '../components/Footer'
import Header from '../components/Header'
import Sidebar from '../components/Sidebar'
import { Route, Switch } from 'react-router-dom';
import { DashboardList } from '../components/DashboardList'
import { Home } from './Home'
import DashboardDetails from '../components/DashboardDetails'
import QueryList from '../components/QueryList'
import ProtectedRoute from '../auth/ProtectedRoute'
import AuthNav from '../components/AuthNav'
import { useAuth0 } from "@auth0/auth0-react";



const App = () => {
  const { isAuthenticated } = useAuth0();
  return (
    <>
      <Header />
      <div className='dashboard'>
        {isAuthenticated ? <AuthNav /> : ''}

        <Switch>
          <ProtectedRoute path="/dashboard" exact component={DashboardList} />
          <ProtectedRoute path="/dashboard/:name" exact component={DashboardDetails} />
          <ProtectedRoute path='/queries' component={QueryList} />
          <Route path="/" exact component={Home} />
        </Switch>

      </div>

      <Sidebar />

      <Footer />
    </>

  );
}

export default App
