import React from 'react';
import closeIcon from '../assets/svg/close.svg';

const Modal = ({ show, close, children, title, hasStyle }) => {

  return (
    <div className={`newModal__wrapper ${show ? 'newModal__wrapper--shown' : ''}`}>

      <div
        onClick={close}
        className={`newModal__backdrop `}
      />

      <div className={`newModal ${show ? 'newModal--shown' : ''}`} >
        <div className='newModal__header'>
          <h2 className='newModal__title'>{title}</h2>
          {/* //TODO - Add the X button here to close the modal */}
          <img
            src={closeIcon}
            alt='Close'
            // type='image/svg+xml' 
            // data={closeIcon}
            className='newModal__closeIcon'
            onClick={close}
          />
        </div>
        <div className='newModal__body'>
        {children}

        </div>
        
      </div>
    </div>
  )
}

export default Modal;