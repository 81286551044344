import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import downArrow from '../assets/svg/down-arrow.svg';


const DropdownMenu = () => {
  const { user, logout } = useAuth0()
  const { name, picture } = user
  return (
    <div className='dropdownMenu'>
      
      <img src={picture} alt='userIcon' className='dropdownMenu__picture' />
      <div className='dropdownMenu__profile'>
        <h2 className='dropdownMenu__profile_name'>{name}</h2>
        <div className='dropdownMenu__account'>My Account
          <img src={downArrow} className='dropdownMenu__arrow' alt='downArrow' />
        </div>
        <ul className='dropdownMenu__list'>
          <li className='dropdownMenu__list-item'>My Profile</li>
          <li className='dropdownMenu__list-item'>Change Password</li>
          <li className='dropdownMenu__list-item'>Setting</li>
          <hr className='dropdownMenu__underline' />
          <li className='dropdownMenu__list-item'
            onClick={() =>
              logout({
                returnTo: window.location.origin,
              })
            }
          >Log Out</li>
        </ul>
      </div>
    </div>

  )
}
export default DropdownMenu;
