import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import expandIcon from '../assets/svg/expand.svg';
import { useAuth0 } from "@auth0/auth0-react";
import { api, getAuthGetConfig } from "../api";



export const NavBarList = ({ toggleModal }) => {

  const [show, setShow] = useState(false);
  const [isDashboardCreate, setIsDashboardCreate] = useState(false)
  const { getAccessTokenSilently } = useAuth0();


  useEffect(() => {
    const getPermission = async () => {
      const token = await getAccessTokenSilently();

      fetch(api.createDashboard, getAuthGetConfig(token))
        .then((response) => {
          // console.log(response)
          if (!response || !response.ok) {
            response.status === 403 && setIsDashboardCreate(false)

            throw Error(response.status);
          }
          return response.json();
        })
        .then((data) => {
          setIsDashboardCreate(true)
          // setIsDashboardCreate()
        })
        .catch(err => {
          console.log("couldn't fetch permission", err);
        })
    }

    getPermission();
  }, [])

  return (
    <section className='subHeader'>
      <NavLink
        to='/dashboard'
        exact
        className='subHeader__dropdown' >
        Dashboards
        <object
          type='image/svg+xml'
          className={`subHeader__dropdownIndicator `}
          data={expandIcon}>ICON</object>
      </NavLink>

      <NavLink
        to='/queries'
        style={{ marginLeft: '2rem' }} className='subHeader__dropdown'>
        Queries <object
          type='image/svg+xml'
          className={`subHeader__dropdownIndicator`}
          data={expandIcon}>ICON</object>
      </NavLink>

      {isDashboardCreate ?
        <div style={{ backgroundColor: '#3A405A', color: '#fff', marginLeft: '3rem' }} className='subHeader__dropdown' onClick={() => setShow(!show)}>
          Create <object
            type='image/svg+xml'
            className={`subHeader__dropdownIndicator ${show ? 'subHeader__dropdownIndicator--reversed' : ''
              }`}
            data={expandIcon}>ICON</object>

          <div className={`subHeader__dropdownContent ${show ? 'subHeader__dropdownContent--shown' : 'subHeader__dropdownContent--hidden'
            }`}>

            <button
              type='button'
              className='subHeader__dropdownOption'
            >Query</button>

            <button
              type='button'
              className='subHeader__dropdownOption'
              onClick={toggleModal}
            >Dashboard</button>

          </div>
        </div> : ''}




    </section>
  )
}
