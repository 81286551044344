import moment from 'moment';

export const convertDate = date => {
  var momentDate = moment(date).format('D/MM/YY h:mm ');
  return momentDate;
}


export const  adjustOverFlows = (isOverflow) => {
  if (isOverflow) {
    document.querySelector("body").style.overflow = 'hidden';
    document.querySelector("html").style.overflow = 'hidden';
  } else {
    document.querySelector("body").style.overflow = 'visible';
    document.querySelector("html").style.overflow = 'visible';
  }
}