import React, { Component } from 'react';
import Loader from 'react-loader-spinner';
import { DashboardList } from './DashboardList';
import Modal from './Modal';
import { api, getAuthGetConfig } from '../api';
import { NavBarList } from './NavBarList';
import { adjustOverFlows } from '../hepler';
import DashboardDetails from './DashboardDetails';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { withAuth0 } from '@auth0/auth0-react';

class AuthNav extends Component {
  constructor(props) {
    super(props)

    this.state = {
      dashboardNameByInput: '',
      showDropdown: '',
      dashboardDropdown: false,
      queryDropdown: false,
      createDropdown: false,
    }
  }

  createDashboard = async (e) => {
    e.preventDefault();
    // this.setState({ modalToggle: false },
    //   () => {
    //     adjustOverFlows(this.state.modalToggle);
    //     this.props.history.push(`/dashboard/${'demo'}`)
    //   })
    // console.log('object')
    const token = await this.props.auth0.getAccessTokenSilently();
    fetch(`${api.createDashboard}?name=${this.state.dashboardNameByInput}`, getAuthGetConfig(token))
      .then((response) => {
        if (!response || !response.ok) throw Error(response.status);
        return response.json();
      })
      .then(({ result }) => {
        // console.log(result.data, 'adasdas')
        this.setState({
          modalToggle: false,
          // dashboardData: [],
          // showDetailDashboard: true,
          // dashboardName: result.data.name
        },
          () => {
            adjustOverFlows(this.state.modalToggle)
            this.props.history.push(`/dashboard/${result.data.name}`)
          })
      })
      .catch(err => {
        console.log("Couldn't get dashboard details", err);
      })
  }



  toggleModal = () => {
    this.setState({ modalToggle: !this.state.modalToggle, dashboardNameByInput: '' },
      () => adjustOverFlows(this.state.modalToggle))
  }


  render() {
    const {
      dashboardNameByInput,
    } = this.state
    return (
      <>
        <NavBarList toggleModal={this.toggleModal} />

        <Modal show={this.state.modalToggle} close={this.toggleModal} title='New Dashboard' hasStyle>
          <form className='dashboard__form' onSubmit={this.createDashboard}>
            <input className='dashboard__inputForm' required
              type='text' placeholder='Dashboard Name' name='dashboard'
              value={dashboardNameByInput}
              onChange={(e) => this.setState({ dashboardNameByInput: e.target.value })} />
            <div className='dashboard__button_wrapper'>
              <button
                type="button"
                className="dashboard__button"
                onClick={this.toggleModal}
              >Close</button>

              <button
                type="submit"
                className="dashboard__button"
              >Save</button>
            </div>

          </form>
        </Modal>
      </>
    )
  }
}

export default compose(withRouter, withAuth0)(AuthNav);
