import React, { Component } from 'react';
import Modal from './Modal';
import { adjustOverFlows } from '../hepler';
import { api, getAuthGetConfig } from '../api';
import Select from 'react-select';
import Table from './Table';
import close from '../assets/svg/close.svg';
import BarGraph from './BarGarph';
import { withAuth0 } from '@auth0/auth0-react';


class DashboardDetails extends Component {
  state = {
    toggleModal: false,
    queryListData: [],
    visualList: [],
    isShowEdit: false,
    querySelectedOption: {},
    visualSelectedOption: {},
    tableData: [],
    tableVisualID: 0,
    chartVisualID: 0,
    chartData: [],
    chartMappingKey: {},
  }

  getQueryList = () => {

    this.setState({
      toggleModal: !this.state.toggleModal,
      queryListData: [],
      visualList: [],
      querySelectedOption: {},
      visualSelectedOption: {},
    }, async () => {
      adjustOverFlows(this.state.toggleModal);

      const token = await this.props.auth0.getAccessTokenSilently();
      
      fetch(api.publishedQueryList, getAuthGetConfig(token))
        .then(res => {
          if (!res || !res.ok) throw Error(res.status);
          return res.json();
        })
        .then(({ result }) => {
          this.setState({ queryListData: result.data })
        })
        .catch(err => {
          console.log("Couldn't get query list", err);
        })
    })
  }

  handleModal = () => {
    this.setState({ toggleModal: !this.state.toggleModal, dashboardName: '' },
      () => adjustOverFlows(this.state.toggleModal))
  }

  // getQueryDetails = (name) => {
  //   console.log(name, 'name from secrch box')
  // }

  getVisualList = (option) => {
    this.setState({ querySelectedOption: option }, async () => {
      const token = await this.props.auth0.getAccessTokenSilently();

      fetch(`${api.visualList}?id=${this.state.querySelectedOption.id}`, getAuthGetConfig(token))
        .then(res => {
          if (!res || !res.ok) throw Error(res.status);
          return res.json();
        })
        .then(({ result }) => {
          console.log(result, 'visual list ')
          this.setState({ visualList: result.data })
        })
        .catch(err => {
          console.log("Couldn't get query list", err);
        })
    })
  }

  handleVisualList = (option) => {
    console.log(option, 'selected visual')
    this.setState({ visualSelectedOption: option })
  }

  getVisualData = async () => {
    const token = await this.props.auth0.getAccessTokenSilently();

    fetch(`${api.addWidget}?id=${this.state.querySelectedOption.id}`, getAuthGetConfig(token))
      .then(res => {
        if (!res || !res.ok) throw Error(res.status);
        return res.json();
      })
      .then(({ result }) => {
        console.log(result)

        if (this.state.visualSelectedOption.label === 'Chart') {
          let rawData = [];
          console.log('chart')
          //**for use of chart **/
          let rowKeys = Object.keys(result.data.data.rows[0])//all rows key's
          let tempKeys = [];
          let mappingKey = Object.keys(result.data.mapping)

          //getting tempkeys which will not further in use 
          rowKeys.map(key => !(mappingKey.includes(key)) && tempKeys.push(key))


          let tempChartData = [];
          //delete object properties which will not reqiured in rows with help of tempkeys and saved on tempChartData
          [result.data.data.rows].map(arr => {
            return arr.forEach(row => {
              for (const p of tempKeys) {
                (p in row) && (delete row[p]);
              }
              tempChartData.push(row)
            }
            )
          })

          //regenerate data to visual State
          rawData.push({
            data: tempChartData,
            name: this.state.querySelectedOption.label,
          }, this.state.chartVisualID)

          this.setState((prevState) => ({
            chartData: [...prevState.chartData, rawData],
            chartVisualID: prevState.chartVisualID + 1,
            chartMappingKey: mappingKey
          }), () => console.log(this.state.chartData, 'chart state',this.state.chartMappingKey))
        }

        else {
          let rawData = [];
          console.log('table')
          //****for use of table****
          //generate data to visual State
          rawData.push({
            data: result.data.data,
            name: this.state.querySelectedOption.label,
            // id: this.state.tableVisualID
          }, this.state.tableVisualID)

          this.setState((prevState) => ({
            tableData: [...prevState.tableData, rawData],
            tableVisualID: prevState.tableVisualID + 1,
          }), () => console.log(this.state.tableData, 'table state'))
        }
      })
      .catch(err => {
        console.log("Couldn't get visual data", err);
      })
  }

  onSubmitAddWidget = (e) => {
    e.preventDefault();
    this.setState({ toggleModal: !this.state.toggleModal, }, () => {
      adjustOverFlows(this.state.toggleModal);
      this.getVisualData();
    })

  }

  removeTable = (id) => {
    this.setState({ tableData: this.state.tableData.filter((item) => item[1] !== id) })
  }

  removeChart = (id) => {
    this.setState({ chartData: this.state.chartData.filter((item) => item[1] !== id) })
  }
  render() {

    return (
      <>
        <div className='dashboardDetails'>
          <div className='dashboardDetails__add_section'>
            <h1 className='dashboardDetails__heading'>{this.props.match.params.name}</h1>
            <button type='button'
              className='dashboardDetails__addButton'
              onClick={this.getQueryList}>Add Widget</button>
          </div>
          <div className='dashboardDetails__editing_mode'>
            {this.state.tableData.length > 0 ?
              <>
                {this.state.tableData.map((table, i) => {
                  return <div key={i} className='dashboardDetails__table_sec'>
                    <div className='dashboardDetails__table_header'>
                      <p className='dashboardDetails__table_name'>{table[0].name}</p>
                      <img className='dashboardDetails__table_close' alt='close' onClick={() => this.removeTable(table[1])} src={close} />
                    </div>

                    <div className='dashboardDetails__table'>
                      <Table isGeneric tData={table[0].data} />
                    </div>

                  </div>
                })}

              </> : ''}

            {this.state.chartData.length > 0 ?
              <>
                {this.state.chartData.map((chart, i) => {
                  return <div key={i} className='dashboardDetails__table_sec'>
                    <div className='dashboardDetails__table_header'>
                      <p className='dashboardDetails__table_name'>{chart[0].name}</p>
                      <img className='dashboardDetails__table_close' alt='close' onClick={() => this.removeChart(chart[1])} src={close} />
                    </div>

                    <div className='dashboardDetails__table'>
                      <BarGraph
                        id={chart[1]}
                        xLabelKey='application_date'
                        yLabelKey='salary'
                        legend='Salary'
                        graphData={chart[0].data}
                      />
                    </div>

                  </div>
                })}

              </> : ''}


          </div>
        </div>
        <Modal show={this.state.toggleModal} close={this.handleModal} hasStyle title='Add Widget'>
          <form className='dashboardDetails__form' onSubmit={this.onSubmitAddWidget}>

            {this.state.queryListData &&
              <>
                <label className='dashboardDetails__modal_selectOptionHeading'>Search a query by name</label>
                <Select
                  value={this.state.querySelectedOption}
                  className='dashboardDetails__modal_selectOption'
                  // placeholder='Search a query by name'
                  onChange={this.getVisualList}
                  options={this.state.queryListData} />
              </>
            }

            {this.state.visualList.length > 0 &&
              <>
                <label className='dashboardDetails__modal_selectOptionHeading'>Choose Visualization</label>
                <Select
                  value={this.state.visualSelectedOption}
                  className='dashboardDetails__modal_selectOption'
                  onChange={this.handleVisualList}
                  options={this.state.visualList} />
              </>
            }

            <div className='dashboardDetails__button_wrapper'>
              <button
                type="button"
                className="dashboard__button"
                onClick={this.handleModal}
              >Cancel</button>

              <button
                type="submit"
                className="dashboardDetails__button"
              >Add to Dashboard</button>
            </div>

          </form>

        </Modal>
      </>
    )
  }
}

export default withAuth0(DashboardDetails)
