import React from "react";
import Loader from 'react-loader-spinner';


const Loading = () => (
  <Loader
    type="TailSpin"
    color="#d21034"
    height={100}
    width={100}
    style={{ textAlign: 'center' }
    }

  />
);

export default Loading;
