import React from 'react'


export const DetailChip = ({ icon, name }) => {
  return (
    <div className='detailChip'>
      <img src={icon} className='detailChip__icon' />
      <h5 className='detailChip__name'>{name}</h5>
    </div>
  )
}
