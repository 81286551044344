import React from 'react';
import facebook from '../assets/svg/facebook.svg';
import linkedin from '../assets/svg/linkedin.svg';
import twitter from '../assets/svg/twitter.svg';
import envelope from '../assets/svg/envelope.svg';
import brand from '../assets/brand.png';




export const Footer = () => {
  return (
    <div className='footer'>
      <div className='footer__branding_sec'>
        <img src={brand} className='footer__branding_icon' alt='brand' />
        <h5 className='footer__branding_patent'>We Empower Companies to Transform</h5>
        <div className='footer__branding_social_media'>
          <img src={facebook} className='footer__branding_social_icon' alt='facebook' />
          <img src={twitter} className='footer__branding_social_icon' alt='twitter' />
          <img src={linkedin} className='footer__branding_social_icon' alt='linkedin' />
          <img src={envelope} className='footer__branding_social_icon' alt='mail' />
        </div>
      </div>
      <div className='footer__company-sec'>
        <div className='footer__company'>
          <h5 className='footer__company_info'>Company Information</h5>
          <p className='footer__company_content'>SoftwareONE’s 3000+ technology consultants across 145 countries help customers
          optimize their software spend by ensuring their IT investments are cost-effectively
          roadmapped from a compliance , commercial, and technical perspective through our
        comprehensive Software Portfolio Management methodology.</p>
        </div>
        <div className='footer__company-links'>
          <p className='footer__company_info'>Useful Links</p>
          <ul className='footer__links'>
            <li className='footer__link'>About Us</li>
            <li className='footer__link'>Privacy Statement</li>
            <li className='footer__link'>Imprint</li>
            <li className='footer__link'>Code of Conduct</li>
            <li className='footer__link'>Conditions</li>
          </ul>
        </div>
        <div className='footer__company-links'>
          <p className='footer__company_info'>Contact</p>
          <ul className='footer__links'>
            <li className='footer__link'>SoftwareONE Deutschland GmbH</li>
            <li className='footer__link'>Konrad-Zuse-Platz 2</li>
            <li className='footer__link'>Munich, D 81829</li>
            <li className='footer__link'>Germany</li>
            <li className='footer__link'>Email: info.de@softwareone.com</li>
            <li className='footer__link'>Phone: (+) 49-71313820300</li>
          </ul>
        </div>
      </div>
    </div>
  )
}
