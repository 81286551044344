import React from 'react'

const QueryList = () => {
  return (
    <div>
      Query List
    </div>
  )
}

export default QueryList
