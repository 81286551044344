import React, { useEffect, useState } from 'react'
import Table from './Table';
import { api, getAuthGetConfig } from '../api'
import Loading from './Loading';
import { useAuth0 } from "@auth0/auth0-react";


export const DashboardList = () => {

  const [dashboardLoader, setDashboardLoader] = useState(true);
  const [dashboardDataList, setDashboardDataList] = useState([]);
  const { getAccessTokenSilently } = useAuth0();


  useEffect(() => {
    fetchDashboardList();
  }, [])


  const fetchDashboardList = async () => {

    const token = await getAccessTokenSilently();

    fetch(api.dashboardList, getAuthGetConfig(token))
      .then((response) => {
        
        if (!response || !response.ok) {
          response.status === 403 && fetchPublishedDashboard()

          throw Error(response.status);
        }
        return response.json();
      })
      .then(({ result }) => {
        console.log(result, 'adasdas')
        setDashboardDataList(result.data)
        setDashboardLoader(false)
        // this.setState({ dashboardDataList: result.data, dashboardLoader: false })
      })
      .catch(err => {
        console.log("Couldn't get dashboard list", err);
      })
  }

  const fetchPublishedDashboard = async () => {
    console.log('published called')
    const token = await getAccessTokenSilently();

    fetch(api.publishedDashboard, getAuthGetConfig(token))
      .then((res) => {
        if (!res || !res.ok) throw Error(res.status);
        return res.json();
      })
      .then(({ result }) => {
        console.log(result, 'adasdas')
        setDashboardDataList(result.data)
        setDashboardLoader(false)
      })
      .catch(err => {
        console.log("Couldn't get published dashboard list", err);
      })
  }



  return (
    <>
      {
        dashboardLoader ?
          <Loading /> : dashboardDataList.length > 0 ?
            <>
              <div className='dashboardTable'>
                <h1 className='dashboardTable__heading'>Dashboards</h1>
                <Table isGeneric={false} tData={dashboardDataList} />

              </div>
            </> : ''
      }
    </>

  )


}
