import React, { useState } from 'react';
import titleIcon from '../assets/icon-white.png';
import homeIcon from '../assets/svg/home.svg';
import inventoryIcon from '../assets/svg/inventory.svg';
import serviceIcon from '../assets/svg/customer.svg';
import analysisIcon from '../assets/svg/analysis.svg';
import helpIcon from '../assets/svg/help.svg'
import settingIcon from '../assets/svg/settings.svg'
import { DetailChip } from './DetailChip';
import cloudIcon from '../assets/svg/cloud.svg';
import compassIcon from '../assets/svg/compass.svg'
import { inventoryDetails, analyzeDetails, helpDetails } from '../static';
import { NavLink } from 'react-router-dom';


const Sidebar = () => {
  const [service, setService] = useState(false)
  const [inventory, setInventory] = useState(false)
  const [analyze, setAnalyze] = useState(false)
  const [help, setHelp] = useState(false)
  const [setting, setSetting] = useState(false)


  const showDetailItem = (item) => {
    switch (item) {
      case 'service': setService(true)
        break;
      case 'inventory': setInventory(true)
        break;
      case 'analyze': setAnalyze(true)
        break;
      case 'help': setHelp(true)
        break;
      case 'setting': setSetting(true)
        break;

      default:
        break;
    }

  }
  const hideDetailsItem = (item) => {
    switch (item) {
      case 'service': setService(false)
        break;
      case 'inventory': setInventory(false)
        break;
      case 'analyze': setAnalyze(false)
        break;
      case 'help': setHelp(false)
        break;
      case 'setting': setSetting(false)
        break;

      default:
        break;
    }
  }


  return (
    <>
      <aside className='sidebar'>
        <ul className='sidebar__nav'>
          <li className='sidebar__list'>
            <img src={titleIcon} alt='title' className='sidebar__list-icon' />
          </li>

          <NavLink to='/' className='sidebar__list'>
            <img src={homeIcon} alt='home' className='sidebar__list-icon' />
            <h3 className='sidebar__list-name'>Home</h3>
          </NavLink>

          {/* <li className='sidebar__list' >
            <img src={serviceIcon} alt='Services' className='sidebar__list-icon' />
            <h3 className='sidebar__list-name'>Services</h3>

            <div className='sidebar__list-subsidebar' >
              <DetailChip name='Explore Services' icon={compassIcon} />
              <h3 className='sidebar__list-subsidebar_title sidebar__list-subsidebar_title--last-child'>SoftwareONE Services</h3>
              <DetailChip name='Managed Cloud' icon={cloudIcon} />
            </div>

          </li> */}

          {/* <li className='sidebar__list' onMouseEnter={() => showDetailItem('inventory')} onMouseLeave={() => hideDetailsItem('inventory')}>
            <img src={inventoryIcon} alt='inventory' className='sidebar__list-icon' />
            <h3 className='sidebar__list-name'>Inventory</h3>

            <div className='sidebar__list-subsidebar' >
              <h3 className='sidebar__list-subsidebar_title'>Software</h3>
              <DetailChip name='Software Assets' icon={compassIcon} />
              <h3 className='sidebar__list-subsidebar_title sidebar__list-subsidebar_title--last-child '>Transaction</h3>
              {inventoryDetails.map((item, i) => {
                return <DetailChip key={i} {...item} />
              })}
            </div>
          </li> */}

          <li className='sidebar__list' onMouseEnter={() => showDetailItem('analyze')} onMouseLeave={() => hideDetailsItem('analyze')}>
            <img src={analysisIcon} alt='Analyze' className='sidebar__list-icon' />
            <h3 className='sidebar__list-name'>Analyze</h3>

            <div className='sidebar__list-subsidebar' >
              <h3 className='sidebar__list-subsidebar_title'>Analytics</h3>
              {analyzeDetails.map((item, i) => {
                return <DetailChip key={i} {...item} />
              })}

              <h3 className='sidebar__list-subsidebar_title sidebar__list-subsidebar_title--last-child '>Exports</h3>
              <DetailChip name='Reports' icon={compassIcon} />
            </div>
          </li>

          {/* <li className='sidebar__list' onMouseEnter={() => showDetailItem('help')} onMouseLeave={() => hideDetailsItem('help')}>
            <img src={helpIcon} alt='Help' className='sidebar__list-icon' />
            <h3 className='sidebar__list-name'>Help</h3>

            <div className='sidebar__list-subsidebar' >

              {helpDetails.map((item, i) => {
                return <DetailChip key={i} {...item} />
              })}

            </div>
          </li> */}
          <li className='sidebar__list' onMouseEnter={() => showDetailItem('setting')} onMouseLeave={() => hideDetailsItem('setting')}>
            <img src={settingIcon} alt='Help' className='sidebar__list-icon' />
            <h3 className='sidebar__list-name'>Setting</h3>

            <div className='sidebar__list-subsidebar' >
              {[{ name: 'Users & Permissions', icon: cloudIcon },
              { name: 'Notifications', icon: cloudIcon }].map((item, i) => {
                return <DetailChip key={i} {...item} />
              })}

              <h3 className='sidebar__list-subsidebar_title sidebar__list-subsidebar_title--last-child '>Purchasing</h3>
              {[{ name: 'Credit Cards', icon: cloudIcon },
              { name: 'Approval Workflow', icon: cloudIcon }].map((item, i) => {
                return <DetailChip key={i} {...item} />
              })}
              <h3 className='sidebar__list-subsidebar_title sidebar__list-subsidebar_title--last-child '>Setup</h3>
              <DetailChip name='Data Integrations' icon={cloudIcon} />
            </div>
          </li>
        </ul>
      </aside >

    </>
  )

}

export default Sidebar
