import React, { Component } from 'react'
import $ from 'jquery';
import 'datatables.net-dt/css/jquery.dataTables.css';
import { convertDate } from '../hepler';

$.DataTable = require('datatables.net');

class Table extends Component {
  componentDidMount() {
    this.$el = $(this.el);
    this.$el.DataTable({
      pageLength: 10,
      lengthChange: false,
      searching: false,
    });
  }
  componentWillUnmount() {
    this.$el.DataTable().destroy(true);
  }
  render() {
    const { tData, isGeneric } = this.props
    console.log(tData)
    return (
      <table className='display cell-border' ref={el => (this.el = el)}>
        {isGeneric ?
          <>
            <thead>
              <tr>
                {tData.columns.map((data, i) => {
                  return <th key={i}>{data.name}</th>
                })}
              </tr>
            </thead>

            <tbody>
              {tData.rows.map((item, i) => {
                    
                  return (
                    <tr key={i}>
                      {Object.keys(item).map((ele, i) => {
                        return <td key={i}>{item[ele]}</td>
                      })}
                    </tr>
                  );
                })}

              {/*--------showing---- */}
              {/* {Array(tData).map((item) => {
                item.rows.map((row, i) => {
                  return (
                    <tr key={i}>
                      { Object.keys(row).map((ele, i) => ele === item.columns.map(col => col['name']) ? <td key={i}>{row[ele]}</td> : ''
                      )}
                    </tr>
                  )

                }
                )
              })} */}
            </tbody>
          </> :
          <>
            <thead>
              <tr>
                <th>Name</th>
                <th>Created At</th>
              </tr>
            </thead>

            <tbody>
              {tData.map((item, i) => {
                return (
                  <tr key={i}>
                    <td>
                      {item.name}<br />
                      <div className='table__status'>{item.status === true ? 'Unpublished' : 'Published'}</div>
                    </td>
                    <td>{convertDate(item.created_at)}</td>
                  </tr>
                );
              })}
            </tbody>
          </>}
      </table>
    )
  }
}

export default Table
