// import { BASE_URL } from './environment';

const BASE_URL = process.env.REACT_APP_API_URL;

export const api = {
  login: BASE_URL + '/api/login/loginDetails',
  dashboardList: BASE_URL + '/api/redash/dashboard',
  publishedDashboard: BASE_URL + '/api/redash/published-dashboard',
  createDashboard: BASE_URL + '/api/redash/dashboard/create',
  publishedQueryList: BASE_URL + '/api/redash/dashboard/published-query-lists',
  visualList: BASE_URL + '/api/redash/dashboard/visual-lists',
  addWidget: BASE_URL + '/api/redash/dashboard/widget',
  publicMsg: BASE_URL + '/api/redash/public-message',

}
export const getAuthGetConfig = token => ({
  method: 'GET',
  headers: {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json'
  }
});
